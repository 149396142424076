import React, { useState, useEffect } from "react";
import {
  format,
  subMonths,
  addMonths,
  subYears,
  addYears,
  isEqual,
  getDaysInMonth,
  getDay,
} from "date-fns";
import { CONSTANTS } from "../constants";
import Alert from "../../../uiwrappers/Alert/Alert";

type DatepickerType = "date" | "month" | "year";

interface IDayCount {
  day: number;
  active?: boolean;
}

export default function Calendar({
  selectedDate,
  setSelectedDate,
  selectedTime,
  showTimeSelect = true,
  handleChangeTime,
  setDateValue,
  isToday,
  datepickerHeaderDate,
  setDatepickerHeaderDate,
  setDisabled,
  setError,
  error,
}: any) {
  const DAYS = ["S", "M", "T", "W", "T", "F", "S"];
  const [dayCount, setDayCount] = useState<Array<IDayCount>>([]);
  const [blankDays, setBlankDays] = useState<Array<number>>([]);

  const [type, setType] = useState<DatepickerType>("date");

  function decrement() {
    switch (type) {
      case "date":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      case "month":
        setDatepickerHeaderDate((prev) => subYears(prev, 1));
        break;
      case "year":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
    }
  }

  function increment() {
    switch (type) {
      case "date":
        setDatepickerHeaderDate((prev) => addMonths(prev, 1));
        break;
      case "month":
        setDatepickerHeaderDate((prev) => addYears(prev, 1));
        break;
      case "year":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
    }
  }

  function getDayCount(date: Date) {
    const daysInMonth = getDaysInMonth(date);
    const dateObj = new Date(date.getFullYear(), date.getMonth(), 1);
    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);
    // find where to start calendar day of week
    const dayOfWeek = getDay(dateObj);
    const blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      dateObj.setDate(i);
      // check if dateObj is today
      const isToday = isEqual(dateObj, todaysDate);
      if (isToday) {
        daysArray.push({ day: i, active: true });
      } else {
        if (dateObj < todaysDate) {
          daysArray.push({ day: i, active: false });
        } else {
          daysArray.push({ day: i, active: true });
        }
      }
    }
    setBlankDays(blankdaysArray);
    setDayCount(daysArray);
  }

  function setMonthValue(month: number) {
    setDatepickerHeaderDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        month,
        datepickerHeaderDate.getDate()
      )
    );
    setType("date");
  }

  function showMonthPicker() {
    setType("month");
  }

  function showYearPicker() {
    setType("date");
  }

  useEffect(() => {
    getDayCount(datepickerHeaderDate);
  }, [datepickerHeaderDate]);

  useEffect(() => {
    const hours = selectedTime.split(":")[0];
    const minutes = selectedTime.split(":")[1];
    const date = new Date(selectedDate);
    date.setHours(hours);
    date.setMinutes(minutes);
    const parsedDate = Date.parse(date);

    if (isNaN(parsedDate) === true) {
      setError("Please enter a valid date");
      setDisabled(true);
      return;
    } else if (parsedDate < 0) {
      setError("Please enter a valid date");
      setDisabled(true);
      return;
    } else if (parsedDate - Date.parse(new Date()) < 0) {
      setError("Please enter a date in the future");
      setDisabled(true);
    } else {
      setError("");
      setDisabled(false);
    }
    try {
      // 1. checking if month
      // 2. checking if month is a valid month
      // 3. checking regex if it fits a 3 letter word, space, 1 or 2 digit day, year
      if (
        selectedDate.length > 3 &&
        CONSTANTS.SHORT_MONTH_NAME.includes(
          selectedDate.substring(0, 3).toLowerCase()
        ) &&
        /^\w{3}\s\d{1,2}\,\s\d{4}$/.test(selectedDate)
      ) {
        setDatepickerHeaderDate(date);
      }
    } catch (e) {
      console.log("error:", e);
    }
  }, [selectedDate, selectedTime]);

  function handleChangeSelectedDate(event: any) {
    setSelectedDate(event.target.value);
  }

  return (
    <div className="flex items-center justify-center">
      <div className="antialiased sans-serif">
        <div>
          <div className="container m-auto py-4">
            <div className="flex sm:flex-row flex-col-reverse justify-center items-center w-full m-auto">
              <div className="flex mt-4 sm:mt-0 sm:mr-6 sm:w-2/5">
                <div className="bg-white rounded-lg shadow p-3 w-full">
                  <div className="flex justify-center items-center mb-2">
                    <div>
                      <button
                        type="button"
                        className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                        onClick={decrement}
                      >
                        <svg
                          className="h-6 w-6 text-gray-500 inline-flex"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 19l-7-7 7-7"
                          />
                        </svg>
                      </button>
                    </div>
                    {type === "date" && (
                      <div
                        onClick={showMonthPicker}
                        className="flex-grow p-1 text-h3-bold text-gray-800 rounded-lg cursor-pointer"
                      >
                        <p className="text-right">
                          {format(datepickerHeaderDate, "MMMM")}
                        </p>
                      </div>
                    )}
                    <div
                      onClick={showYearPicker}
                      className="flex-grow p-1 text-h3-bold text-gray-800 rounded-lg cursor-pointer"
                    >
                      <p
                        className={`${
                          type === "date" ? "text-left" : "text-center"
                        }`}
                      >
                        {format(datepickerHeaderDate, "yyyy")}
                      </p>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                        onClick={increment}
                      >
                        <svg
                          className="h-6 w-6 text-gray-500 inline-flex"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {type === "date" && (
                    <>
                      <div className="flex flex-wrap mb-3 -mx-1">
                        {DAYS.map((day, i) => (
                          <div
                            key={i}
                            style={{
                              width: "14.26%",
                            }}
                            className="px-1"
                          >
                            <div className="text-gray-800 text-center text-xs-medium">
                              {day}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-wrap -mx-1">
                        {blankDays.map((_, i) => (
                          <div
                            key={i}
                            style={{
                              width: "14.26%",
                            }}
                            className="text-center border p-1 border-transparent text-sm-normal"
                          ></div>
                        ))}
                        {dayCount.map((d, i) => (
                          <div
                            key={i}
                            style={{
                              width: "14.26%",
                            }}
                            className="px-1 mb-1"
                          >
                            {!d.active && (
                              <div
                                className={`cursor-pointer text-center text-sm-normal rounded-full transition ease-in-out duration-100
                                 text-gray-400 hover:bg-blue-200
                                `}
                              >
                                {d.day}
                              </div>
                            )}
                            {d.active && (
                              <div
                                onClick={() => setDateValue(d.day)}
                                className={`cursor-pointer text-center text-sm-normal rounded-full transition ease-in-out duration-100 ${
                                  isToday(d.day)
                                    ? "bg-blue-900 text-white"
                                    : "text-gray-700 hover:bg-blue-200"
                                }`}
                              >
                                {d.day}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {type === "month" && (
                    <div className="flex flex-wrap -mx-1">
                      {Array(12)
                        .fill(null)
                        .map((_, i) => (
                          <div
                            key={i}
                            onClick={() => setMonthValue(i)}
                            style={{ width: "25%" }}
                          >
                            <div className="cursor-pointer p-5 text-center text-sm-semibold rounded-lg hover:bg-gray-200">
                              {format(
                                new Date(
                                  datepickerHeaderDate.getFullYear(),
                                  i,
                                  datepickerHeaderDate.getDate()
                                ),
                                "MMM"
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}{" "}
                  {type === "year" && <></>}
                </div>
              </div>
              <div className="flex justify-center w-full sm:w-1/4 h-full grow">
                <div className="flex flex-col w-full">
                  <input type="hidden" name="date" />
                  <div className="flex space-x-2 sm:block sm:space-x-0 sm:space-y-4">
                    <input
                      type="text"
                      className="h-12 w-full p-2 px-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 text-body-medium"
                      placeholder="Select date"
                      value={selectedDate}
                      onChange={handleChangeSelectedDate}
                    />
                    {showTimeSelect && (
                      <input
                        type="time"
                        className="h-12 cursor-pointer w-full p-2 px-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 text-body-medium"
                        style={{ background: "white" }}
                        value={selectedTime}
                        onChange={handleChangeTime}
                      />
                    )}
                  </div>

                  {error && <Alert alertMessage={error} customStyle="w-full" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
