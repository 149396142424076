import useIsMobile from "../../../../hooks/useIsMobile";
import Alert from "../../../uiwrappers/Alert/Alert";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import MobilePostButtons from "./MobilePostButtons";
import { getMaxPhotosText } from "./utils";

export default function PostButtons({
  toggleGoogleStatus,
  toggleFacebookStatus,
  toggleInstagramStatus,
  handleGeneratePost,
  handleRewriteForMe,
  rewritePostErrorMessage,
  startGenerateMedia,
  attachedPhotos,
  attachingPhoto,
  setAttachedPhotos,
  processImages,
  openAttachedPhotosModal,
  openPhotoSelectModal,
  slideUpOpen,
  setSlideUpOpen,
  isGroupPost = false,
}: any) {
  const isMobile = useIsMobile();
  return (
    <div className="flex flex-col justify-between w-full pt-3 sm:pb-0">
      {toggleGoogleStatus && (
        <div className="text-sm-normal text-gray-500 pb-2">
          1500 characters max
        </div>
      )}

      <div className="flex space-x-5 sm:pb-0 pb-2">
        <PrimaryLink
          text="Generate Post"
          size="lg"
          icon="sparkles"
          onClickFunc={() => handleGeneratePost([], isGroupPost)}
        />
        <PrimaryLink
          text="Rewrite for me"
          size="lg"
          icon="rewrite"
          onClickFunc={handleRewriteForMe}
        />
      </div>

      {isMobile && (
        <div className="flex items-center justify-center">
          <hr className="text-gray-600 w-full mr-3" />
          <div className="flex flex-shrink-0 text-gray-600 text-sm-normal">
            {getMaxPhotosText(
              toggleFacebookStatus,
              toggleGoogleStatus,
              toggleInstagramStatus,
              isGroupPost
            )}
          </div>
          <hr className="text-gray-600 w-full ml-3" />
        </div>
      )}

      {isMobile && (
        <MobilePostButtons
          startGenerateMedia={startGenerateMedia}
          attachedPhotos={attachedPhotos}
          attachingPhoto={attachingPhoto}
          setAttachedPhotos={setAttachedPhotos}
          processImages={processImages}
          openAttachedPhotosModal={openAttachedPhotosModal}
          openPhotoSelectModal={openPhotoSelectModal}
          slideUpOpen={slideUpOpen}
          setSlideUpOpen={setSlideUpOpen}
        />
      )}

      {rewritePostErrorMessage && (
        <div className="flex justify-between flex-wrap align-middle items-center pt-2">
          <Alert alertMessage={rewritePostErrorMessage} />
        </div>
      )}
    </div>
  );
}
