export function SocialContentError({ error }: { error: string | null }) {
  return error ? (
    <div className="sm:mt-2 mt-4">
      <div className="text-red-600 text-sm-normal">
        <span>{error}</span>
      </div>
    </div>
  ) : (
    <></>
  );
}
