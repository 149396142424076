import React from "react";
import { HorizontalTabsProps, TabInfo } from "./types";
import GenericBadge from "../../uiwrappers/GenericBadge/GenericBadge";

const HorizontalTabs: React.FC<HorizontalTabsProps> = ({
  tabs,
  onClick,
  styleType = "tab",
}) => {
  if (styleType === "badge") {
    return (
      <div className="flex justify-between sm:justify-start h-full w-full gap-4 overflow-x-auto no-scrollbar">
        {tabs.map((tab: TabInfo) => (
          <div
            key={tab.name}
            onClick={() => onClick(tab.name)}
            id={`${tab?.name?.toLowerCase()}`}
            className={`
              ${
                tab.selected
                  ? "bg-blue-100 text-blue-700"
                  : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
              } text-sm-normal sm:text-body-normal rounded-lg p-2 px-3 flex flex-col sm:inline-flex sm:flex-row items-center justify-center sm:gap-2 cursor-pointer`}
          >
            <span>{tab.name}</span>
            {tab.name === "Needs Reply" && tab?.count !== undefined && (
              <GenericBadge
                text={tab.count}
                color={
                  tab.count === 0 ? (tab.selected ? "gray" : "blue") : "red"
                }
                invertedColors={tab.count === 0 ? false : true}
              />
            )}
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div
        className="flex justify-start h-full w-full gap-8 overflow-x-auto no-scrollbar border-b border-gray-200"
        id="horizontal-tabs"
      >
        {tabs.map((tab: TabInfo) => (
          <div
            key={tab.name}
            onClick={() => onClick(tab.name)}
            id={`${tab?.name?.toLowerCase()}`}
            className={`
            ${
              tab.selected
                ? "border-blue-600 text-blue-600"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
            } flex flex-col sm:inline-flex sm:flex-row items-center justify-center py-4 px-1 border-b-2 text-sm-medium cursor-pointer whitespace-nowrap sm:gap-2`}
          >
            <span>{tab.name}</span>
            {tab?.count !== undefined && (
              <GenericBadge
                text={tab.count}
                color={tab.selected ? "blue" : "gray"}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
};

export default HorizontalTabs;
