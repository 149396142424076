import { XCircleIcon } from "@heroicons/react/solid";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import GalleryAsset from "../../../common/GalleryAsset/GalleryAsset";
import { generateSubtext, isVideo } from "../utils";
import GenericDropdown from "../../../common/GenericDropdown/GenericDropdown";
import ImageUpload from "../../../uiwrappers/ImageUpload/ImageUpload";
import { UploadIcon } from "@heroicons/react/outline";
import useIsMobile from "../../../../hooks/useIsMobile";

export default function AttachedPhotosDragDrop({
  photos,
  setPhotos,
  openPhotoSelectModal,
  processImages,
  startGenerateMedia,
  setViewingPhoto,
  selectPhoto,
  infoType,
}: any) {
  const isMobile = useIsMobile();

  function onSortEnd(oldIndex: number, newIndex: number) {
    const newPhotos = arrayMove([...photos], oldIndex, newIndex);
    setPhotos(newPhotos);
  }

  function removePhoto(e, index) {
    e.stopPropagation();
    const newPhotos = [...photos];
    newPhotos.splice(index, 1);
    setPhotos(newPhotos);
  }

  function getMediaCounts() {
    let photosCount = 0;
    let videosCount = 0;

    photos.forEach((media: any) => {
      const isPhoto = !isVideo(media);
      if (isPhoto) {
        photosCount += 1;
      } else {
        videosCount += 1;
      }
    });

    const subheading = generateSubtext(photosCount, videosCount);

    return subheading;
  }

  let dropdownArrayAddPhotos = [
    {
      icon: "generateMedia",
      iconPlacement: "leading",
      text: "Generate Media",
      customStyle: "text-blue-600 text-sm-medium",
      onClick: () => {
        startGenerateMedia();
      },
    },
    {
      icon: "photograph",
      iconPlacement: "leading",
      customStyle: "text-sm-medium",
      text: "Select from Gallery...",
      onClick: () => {
        openPhotoSelectModal("attachedPhotosModal");
      },
    },
    {
      customElement: (
        <ImageUpload
          multiple={true}
          processImages={processImages}
          customStyle="w-full"
        >
          <div className="flex w-full">
            <UploadIcon className="w-5 h-5 mr-2 text-gray-500 heroicon-stroke-w-1.0" />
            <div className="text-sm-medium text-gray-600">Upload</div>
          </div>
        </ImageUpload>
      ),
    },
  ];

  if (infoType === "showcase") {
    dropdownArrayAddPhotos = dropdownArrayAddPhotos.filter(
      (item) => item.text !== "Generate Media"
    );
  }

  return (
    <div className="flex flex-col flex-grow">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-gray-900 text-body-semibold">
            {getMediaCounts()}
          </div>
          <div className="text-gray-500 text-sm-normal pt-1">
            Drag to reorder, click to view
          </div>
        </div>

        {!isMobile && (
          <div>
            <GenericDropdown
              displayedItem={{
                text: "Add",
              }}
              dropdownItems={dropdownArrayAddPhotos}
              customStyle="whitespace-nowrap rounded-full text-body-medium text-gray-700 items-center p-4"
              buttonType="secondary"
              justifyDirection="justify-center"
            />
          </div>
        )}
      </div>

      <SortableList
        onSortEnd={onSortEnd}
        className="grid gap-2 grid-cols-3 pt-4"
        draggedItemClassName="dragged"
        allowDrag={true}
      >
        {photos.map((photo: any, index: number) => {
          const selected = photo.selected;
          return (
            <SortableItem key={photo.data}>
              <div
                key={photo.data}
                onMouseDown={() => selectPhoto(index)}
                onClick={() => setViewingPhoto(photo)}
                className={`relative z-50 cursor-pointer ${
                  selected ? "ring-2 ring-blue-500" : ""
                }`}
              >
                <div style={{ height: 90 }}>
                  <GalleryAsset
                    media={photo}
                    className="draggable-item object-cover w-full h-full cursor-pointer"
                  />
                </div>

                <div
                  onMouseDown={(e) => removePhoto(e, index)}
                  className="absolute top-1 right-1 bg-white rounded-full"
                >
                  <XCircleIcon className="w-4 h-4 rounded-full text-gray-600" />
                </div>
              </div>
            </SortableItem>
          );
        })}
      </SortableList>
    </div>
  );
}
