import React, { useCallback, useState } from "react";

import { getMediaTypeExtension, isVideoUrl, timeSince } from "../utils";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import useChurnModal from "../../../../hooks/useChurnModal/useChurnModal";
import AttachedPhotos from "../CreateAPostModal/AttachedPhotos";
import { downloadImage } from "../../marketing/DigitalMedia/utils";
import GenericDropdown from "../../../common/GenericDropdown/GenericDropdown";
import { toast } from "react-toastify";
import { GroupsIcon } from "../../social/Groups/GroupsIcon";
import useIsMobile from "../../../../hooks/useIsMobile";
import { SlideUpModalButton } from "../../../common/SlideUpModal/SlideUpModal";

export interface GroupPostProps {
  post: any;
  setDeletePostModalOpen: (arg0: boolean) => void;
  setEditPostModalOpen: (arg0: boolean) => void;
  setPostToEditIds: (ids: any) => void;
  setPostToEditMessage: (arg0: string) => void;
  setPostToEditPhotos: (arg0: any[]) => void;
  setEditingPost: (arg0: boolean) => void;
  setPostOptionsSlideUpModalOpen: (arg0: boolean) => void;
  setPostOptionsButtons: (arg0: SlideUpModalButton[]) => void;
}

export const handleDownload = async (attachedPhotos: { data: string }[]) => {
  try {
    if (attachedPhotos.length === 1) {
      const extension = getMediaTypeExtension(attachedPhotos[0]);
      await downloadImage(
        attachedPhotos[0].data,
        `group_post_attachment.${extension}`
      );
      toast.success("Downloaded group post attachment");
    } else {
      attachedPhotos.map(async (attachedPhoto, index) => {
        if (attachedPhoto.data) {
          const extension = getMediaTypeExtension(attachedPhoto);
          await downloadImage(
            attachedPhoto.data,
            `group_post_attachment_${index}.${extension}`
          );
        }
      });
      toast.success("Downloaded group post attachments");
    }
  } catch (e) {
    toast.error("Error downloading group post attachments");
  }
};

export default function GroupPost({
  post,
  setDeletePostModalOpen,
  setEditPostModalOpen,
  setPostToEditIds,
  setPostToEditMessage,
  setPostToEditPhotos,
  setEditingPost,
  setPostOptionsSlideUpModalOpen,
  setPostOptionsButtons,
}: GroupPostProps) {
  const isMobile = useIsMobile();
  const { attached_media_urls, content, created, updated, id } = post;

  const [isCopied, setIsCopied] = useState(false);

  const { renderChurnModal, shouldOpenChurnModal } = useChurnModal();

  let attachedPhotos: any[] = [];

  if (attached_media_urls?.length > 0) {
    attachedPhotos = attached_media_urls?.map((photo: string) => ({
      data: photo,
    }));
  }

  post["type"] = "photo";

  function openEditPostModal() {
    if (shouldOpenChurnModal()) {
      return;
    }

    setEditingPost(true);
    setPostToEditIds({ facebook: "", google: "", groupPost: id });
    setEditPostModalOpen(true);
    setPostToEditMessage(content);
    setPostToEditPhotos(attachedPhotos);
  }

  function openDeletePostModal() {
    if (shouldOpenChurnModal()) {
      return;
    }

    setPostToEditIds({ facebook: "", google: "", groupPost: id });
    setDeletePostModalOpen(true);
  }

  const handleCopyClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(post.content);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Message will be visible for 2 seconds
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  }, [post.content]);

  const thumbnailPhotoUrl = attached_media_urls?.length
    ? attached_media_urls[0]
    : "";
  const isVideo = isVideoUrl(thumbnailPhotoUrl);
  const dropdownOptions =
    post.type !== "fb video"
      ? [
          { text: "Edit", onClick: openEditPostModal },
          {
            text: "Delete",
            onClick: openDeletePostModal,
            customStyle: "text-red-600 border-t",
          },
        ]
      : [
          {
            text: "Delete",
            onClick: openDeletePostModal,
            customStyle: "text-red-600 border-t",
          },
        ];

  const postOptionsButtons = [
    {
      type: "primary",
      text: "Edit",
      onClickFunc: () => {
        openEditPostModal();
        setPostOptionsSlideUpModalOpen(false);
      },
    } as SlideUpModalButton,
    {
      type: "delete",
      text: "Delete",
      onClickFunc: () => {
        openDeletePostModal();
        setPostOptionsSlideUpModalOpen(false);
      },
    } as SlideUpModalButton,
  ];

  const DownloadButton = () => {
    return (
      <div
        className="text-xs text-gray-700 border rounded-full border-gray-300 bg-white font-medium py-1.5 px-3 cursor-pointer flex justify-center items-center"
        // onClick={() => axios.get(attachedPhotos[0].url)}
        onClick={() => handleDownload(attachedPhotos)}
      >
        Download
      </div>
    );
  };

  const AssetDetails = () => {
    return (
      <div className="flex flex-row sm:flex-col justify-between items-center sm:justify-center text-center sm:px-3 sm:gap-3 gap-auto w-full">
        <span className="font-medium text-sm text-gray-800 whitespace-nowrap">
          {attachedPhotos.length == 1
            ? isVideo
              ? "Video attachment"
              : "Photo attachment"
            : `${attachedPhotos.length} attachments`}
        </span>
        <DownloadButton />
      </div>
    );
  };

  return (
    <div className="p-4 sm:p-6 w-full bg-white overflow-hidden rounded-lg shadow mb-3">
      {renderChurnModal()}

      <div className="flex justify-between mb-2.5">
        <div className="flex items-center justify-start gap-1.5">
          <GroupsIcon />
          <span className="text-xs text-gray-500">{`Last edited ${timeSince(
            updated
          )} ago `}</span>
        </div>

        {isMobile ? (
          <DotsVerticalIcon
            className="w-auto transform w-9 h-9 text-gray-600 p-1.5"
            onClick={() => {
              setPostOptionsSlideUpModalOpen(true);
              setPostOptionsButtons(postOptionsButtons);
            }}
          />
        ) : (
          <GenericDropdown
            size="dots"
            dropdownItems={dropdownOptions}
            customStyle="border-none bg-white shadow-none focus:bg-white hover:bg-white"
            dotsCustomStyle="w-9 h-9 text-gray-600 p-1.5"
            dropdownItemWidth={"sm"}
            showDotDropdown={true}
          />
        )}
      </div>

      {/* Group post body */}
      <div className="flex flex-col sm:flex-row justify-between items-start text-gray-900 pb-6 border-b gap-6">
        <div
          className={`sm:${
            thumbnailPhotoUrl ? "w-4/6" : "w-full"
          } flex flex-col justify-center`}
        >
          {post.content}
        </div>
        {thumbnailPhotoUrl && (
          <div
            className={`sm:flex w-full sm:w-auto flex flex-row sm:justify-center items-center p-2.5 ${
              isMobile ? "bg-blue-50 border border-blue-200" : "bg-gray-50"
            } rounded-md`}
          >
            <div className="mr-3 sm:mr-2">
              {isVideo ? (
                <video
                  src={thumbnailPhotoUrl}
                  muted
                  autoPlay={!isMobile}
                  loop
                  playsInline
                  className="w-32 rounded-lg"
                >
                  <source />
                </video>
              ) : (
                <div className="flex flex-col flex-shrink-0 w-14 sm:w-36">
                  <div className="relative w-14 sm:w-36">
                    <div className="border-b">
                      <div>
                        <AttachedPhotos
                          attachedPhotos={attachedPhotos}
                          isGroupPost={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <AssetDetails />
          </div>
        )}
      </div>

      {/* Group post footer */}
      <div className="pt-4 flex justify-between sm:justify-end items-center gap-8">
        <div
          className="text-blue-600 font-medium justify-center items-center gap-2 flex"
          onClick={handleCopyClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path
              d="M6.66671 6.16732V12.834C6.66671 13.7545 7.4129 14.5007 8.33337 14.5007H13.3334M6.66671 6.16732V4.50065C6.66671 3.58018 7.4129 2.83398 8.33337 2.83398H12.1549C12.3759 2.83398 12.5878 2.92178 12.7441 3.07806L16.4226 6.75657C16.5789 6.91285 16.6667 7.12482 16.6667 7.34583V12.834C16.6667 13.7545 15.9205 14.5007 15 14.5007H13.3334M6.66671 6.16732H5.33337C4.2288 6.16732 3.33337 7.06275 3.33337 8.16732V16.1673C3.33337 17.0878 4.07957 17.834 5.00004 17.834H11.3334C12.4379 17.834 13.3334 16.9386 13.3334 15.834V14.5007"
              stroke="#2563EB"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span className="whitespace-nowrap cursor-pointer">
            {isCopied ? "Copied!" : "Copy text"}
          </span>
        </div>

        <a
          className="text-gray-600 font-medium justify-center items-center gap-2 flex whitespace-nowrap"
          href="https://www.facebook.com/groups/joins"
          target="_blank"
        >
          <span>Go to Facebook group</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path
              d="M8.33337 5.33464H5.00004C4.07957 5.33464 3.33337 6.08083 3.33337 7.0013V15.3346C3.33337 16.2551 4.07957 17.0013 5.00004 17.0013H13.3334C14.2538 17.0013 15 16.2551 15 15.3346V12.0013M11.6667 3.66797H16.6667M16.6667 3.66797V8.66797M16.6667 3.66797L8.33337 12.0013"
              stroke="#4B5563"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
