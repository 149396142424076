import { XCircleIcon } from "@heroicons/react/solid";
import LoadingSpinner from "../../../uiwrappers/LoadingSpinner/LoadingSpinner";
import { useContext } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import GrayLink from "../../../uiwrappers/GrayLink/GrayLink";
import AttachedPhotos from "./AttachedPhotos";
import ImageUpload from "../../../uiwrappers/ImageUpload/ImageUpload";
import { getMaxPhotosText } from "./utils";

export default function DesktopAttachedPhotos({
  setRewritePostErrorMessage,
  attachingPhoto,
  openAttachedPhotosModal,
  openPhotoSelectModal,
  processImages,
  startGenerateMedia,
  toggleFacebookStatus,
  toggleGoogleStatus,
  toggleInstagramStatus,
  isGroupPost = false,
}: any) {
  const { setIsDigitalAssetAttached, attachedPhotos, setAttachedPhotos } =
    useContext(DesktopContext);

  function generateMedia(e) {
    startGenerateMedia();
  }

  function startPhotoSelect(e) {
    openPhotoSelectModal("createPostModal");
  }

  return (
    <>
      <div style={{ width: 180 }} className="flex flex-col flex-shrink-0">
        <div style={{ width: 180 }} className="relative w-full">
          <XCircleIcon
            onClick={() => {
              setAttachedPhotos([]);
              setIsDigitalAssetAttached(false);
              setRewritePostErrorMessage("");
            }}
            className="z-10 absolute -right-3 -top-3 h-5 w-5 text-gray-600 rounded-full hover:text-white hover:bg-red-600 transform transition duration-500 hover:scale-110 cursor-pointer"
          />

          {attachingPhoto && (
            <div style={{ width: 180, height: 140 }}>
              <div className="flex justify-center items-center w-full h-full rounded-md border border-gray-200">
                <div className="flex justify-center items-center w-24 h-24">
                  <LoadingSpinner color="blue" />
                </div>
              </div>
            </div>
          )}

          {!attachingPhoto && (
            <>
              <div className="border-b pb-4">
                <div onClick={openAttachedPhotosModal}>
                  <AttachedPhotos attachedPhotos={attachedPhotos} />
                </div>
              </div>
              <div className="flex flex-col justify-center px-3">
                <PrimaryLink
                  icon="generateMedia"
                  iconPlacement="leading"
                  text="Generate Media"
                  size="md"
                  customStyle="pt-4 pb-3"
                  onClickFunc={generateMedia}
                />
                <GrayLink
                  icon="photograph"
                  iconPlacement="leading"
                  text="Select from Gallery"
                  size="md"
                  customStyle="py-3"
                  onClickFunc={startPhotoSelect}
                />

                <ImageUpload processImages={processImages} multiple={true}>
                  <GrayLink
                    icon="upload"
                    iconPlacement="leading"
                    text="Upload"
                    size="md"
                    customStyle="pt-3"
                  />
                </ImageUpload>
              </div>
            </>
          )}
        </div>

        <div className="text-gray-500 text-sm-normal pt-4">
          {getMaxPhotosText(
            toggleFacebookStatus,
            toggleGoogleStatus,
            toggleInstagramStatus,
            isGroupPost
          )}
        </div>
      </div>
    </>
  );
}
