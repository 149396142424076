import useIsMobile from "../../../../hooks/useIsMobile";
import GalleryAsset from "../../../common/GalleryAsset/GalleryAsset";

interface Photo {
  url?: string;
  data?: string;
  photo?: string;
}

interface AttachedPhotosProps {
  attachedPhotos: Photo[];
  isGroupPost?: boolean;
}

export default function AttachedPhotos({
  attachedPhotos,
  isGroupPost = false,
}: AttachedPhotosProps) {
  const isMobile = useIsMobile();

  function getPhotoUrl(photo: any) {
    return photo?.url || photo?.data || photo?.photo;
  }

  function renderGalleryAsset(photo: any, additionalClasses = "") {
    return (
      <GalleryAsset
        media={{
          ...photo,
          data: getPhotoUrl(photo),
        }}
        className={`${
          isGroupPost && isMobile ? "w-12 h-12" : "w-full h-full"
        } object-cover ${additionalClasses}`}
      />
    );
  }

  function additionalPhotosOverlay() {
    if (attachedPhotos?.length <= 3) {
      return <></>;
    }

    const additionalCount = attachedPhotos?.length - 3;
    return (
      <>
        <div className="flex items-center justify-center absolute inset-0 bg-black opacity-50 rounded-br-lg"></div>
        <div className="flex items-center justify-center absolute inset-0">
          <div className="z-10 text-white text-h1-medium opacity-100">
            +{additionalCount}
          </div>
        </div>
      </>
    );
  }

  function renderPhotosLayout() {
    if (isGroupPost && isMobile) {
      return (
        <div className="flex w-full cursor-pointer">
          {renderGalleryAsset(attachedPhotos[0], "rounded-lg")}
        </div>
      );
    }

    switch (attachedPhotos?.length) {
      case 1:
        return (
          <div className="flex w-full cursor-pointer">
            {renderGalleryAsset(attachedPhotos[0], "rounded-lg")}
          </div>
        );

      case 2:
        return (
          <div className="flex flex-col cursor-pointer">
            <div style={{ height: isMobile ? "100%" : 88 }}>
              {renderGalleryAsset(attachedPhotos[0], "rounded-t-lg")}
            </div>
            <div className="w-full h-1 bg-white"></div>
            <div style={{ height: isMobile ? "100%" : 88 }}>
              {renderGalleryAsset(attachedPhotos[1], "rounded-b-lg")}
            </div>
          </div>
        );

      default:
        return (
          <div className="flex flex-col cursor-pointer">
            <div style={{ height: isMobile ? "100%" : 118 }}>
              {renderGalleryAsset(attachedPhotos[0], "rounded-t-lg")}
            </div>
            <div className="w-full h-1 bg-white"></div>
            <div
              className="flex w-full"
              style={{ height: isMobile ? "100%" : 58 }}
            >
              <div className="flex w-1/2">
                {renderGalleryAsset(attachedPhotos[1], "rounded-bl-lg")}
              </div>
              <div className="w-1 h-full bg-white"></div>
              <div className="relative flex w-1/2">
                {renderGalleryAsset(attachedPhotos[2], "rounded-br-lg")}
                {additionalPhotosOverlay()}
              </div>
            </div>
          </div>
        );
    }
  }

  return renderPhotosLayout();
}
