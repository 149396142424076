export default function GeneratingPost() {
  return (
    <div className="flex flex-grow w-full h-full bg-white rounded-lg">
      <img
        src="/GeneratePost.gif"
        className="h-32 w-32"
        alt="Generating Post"
      />
    </div>
  );
}
