export default function BigGenerateMediaIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9856 16.5432L24 19.5L23.0144 16.5432C22.4545 14.8635 21.1365 13.5455 19.4568 12.9856L16.5 12L19.4568 11.0144C21.1365 10.4545 22.4545 9.13648 23.0144 7.45683L24 4.5L24.9856 7.45683C25.5455 9.13648 26.8635 10.4545 28.5432 11.0144L31.5 12L28.5432 12.9856C26.8635 13.5455 25.5455 14.8635 24.9856 16.5432Z"
        stroke="#60A5FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9914 24.3255L14.4 26.0996L13.8087 24.3255C13.4727 23.3177 12.6819 22.5269 11.6741 22.191L9.90002 21.5996L11.6741 21.0082C12.6819 20.6723 13.4727 19.8815 13.8087 18.8737L14.4 17.0996L14.9914 18.8737C15.3273 19.8815 16.1181 20.6723 17.1259 21.0082L18.9 21.5996L17.1259 22.191C16.1181 22.5269 15.3273 23.3177 14.9914 24.3255Z"
        stroke="#60A5FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 12H7.75C5.95507 12 4.5 13.4551 4.5 15.25V28.25C4.5 30.0449 5.95508 31.5 7.75 31.5H20.75C22.5449 31.5 24 30.0449 24 28.25V25.5"
        stroke="#60A5FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
