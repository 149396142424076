import React from "react";
import { UserGroupIcon } from "@heroicons/react/solid";

interface GroupsIconProps {
  width?: string;
  height?: string;
  color?: string;
  colorStrength?: string;
}

export const GroupsIcon: React.FC<GroupsIconProps> = ({
  width = "5",
  height = "5",
  color = "blue",
  colorStrength = "600",
}: GroupsIconProps) => {
  // Calculates the hero icon size to be ~70% of the total group icon size
  const heroIconSize = (dimension: string) => {
    return (Math.round(parseFloat(dimension) * 2.8) / 4).toString(10);
  };

  // Calculates the padding needed to center the icon
  const heroIconPadding = (dimension: string) => {
    const padding = Math.floor(
      (parseFloat(dimension) - parseFloat(heroIconSize(dimension))) / 3
    );
    if (padding < 1) {
      return "px";
    } else return padding.toString(10);
  };

  return (
    <div
      className={`flex justify-center border border-white pt-${heroIconPadding(
        height
      )} bg-${color}-${colorStrength} rounded-full w-${width} h-${height} z-auto`}
    >
      <UserGroupIcon
        className={`w-${heroIconSize(width)} h-${heroIconSize(
          height
        )} text-white`}
      />
    </div>
  );
};
