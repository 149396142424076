import UtilityModal from "../../common/UtilityModal/UtilityModal";
import { useEffect, useState } from "react";
import PhotoSelectModalGrid from "./PhotoSelectModalGrid";
import ActionModal from "../../common/ActionModal/ActionModal";

export default function PhotoSelectModalSocial({
  isOpen,
  setIsOpen,
  header,
  onSaveHandler,
  onCancelHandler,
  primaryButtonText,
  showNumberPhotosSelected,
}: any) {
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);

  useEffect(() => {
    setSelectedImages([]);
  }, [isOpen]);

  function selectPhoto(image: any) {
    if (selectedImages.some((i) => i.id === image.id)) {
      const newSelectedImages = selectedImages.filter((i) => i.id !== image.id);
      setSelectedImages(newSelectedImages);
    } else {
      const newSelectedImages = [...selectedImages];
      newSelectedImages.push(image);
      setSelectedImages(newSelectedImages);
    }
  }

  function onSubmitHandler() {
    onSaveHandler(selectedImages);
    setSelectedImages([]);
  }

  function getPrimaryButtonText() {
    if (showNumberPhotosSelected) {
      return `${primaryButtonText} ${
        selectedImages.length > 0 ? `(${selectedImages.length})` : ""
      }`;
    }

    if (primaryButtonText) {
      return primaryButtonText;
    } else {
      return "Save";
    }
  }

  return (
    <UtilityModal
      isOpen={isOpen}
      setIsOpen={() => setIsExitModalOpen(true)}
      onCancelHandler={onCancelHandler}
      title={header}
      width="sm:w-800"
      submitButtonText={getPrimaryButtonText()}
      onSubmitHandler={onSubmitHandler}
    >
      <PhotoSelectModalGrid
        isOpen={isOpen}
        selectPhoto={selectPhoto}
        renderWhiteCircles={true}
        selectedImages={selectedImages}
      />

      <ActionModal
        open={isExitModalOpen}
        setOpen={setIsExitModalOpen}
        header="Are you sure?"
        subheader="Your changes will be lost"
        primaryButtonText="Leave"
        secondaryButtonText="Stay here"
        primaryButtonFunction={() => {
          setIsOpen(false);
          setIsExitModalOpen(false);
        }}
        secondaryButtonFunction={() => setIsExitModalOpen(false)}
      />
    </UtilityModal>
  );
}
