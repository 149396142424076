export function getPathname(router) {
  return router?.pathname?.split("/")[1];
}

export function getMaxPhotosText(
  toggleFacebookStatus,
  toggleGoogleStatus,
  toggleInstagramStatus,
  isGroupPost = false
) {
  if (toggleInstagramStatus && !toggleFacebookStatus && !toggleGoogleStatus) {
    return "Up to 10 photos/videos";
  } else if ((toggleFacebookStatus && !toggleGoogleStatus) || isGroupPost) {
    return "Mult. photos or 1 video";
  } else {
    return "1 photo max";
  }
}
