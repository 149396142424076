import ProgressCircle from "./ProgressCircle";

export default function UploadProgressBar({ progress, uploadingText }: any) {
  return (
    <div className="absolute flex flex-col sm:flex-row w-full h-full justify-center items-center bg-opacity-75 top-0 left-0 z-10 bg-gray-200 bg-transparent">
      <div className="sm:w-auto w-5/6">
        <div className="bg-white px-8 py-6 shadow-xl overflow-hidden rounded-lg">
          <div className="flex items-center">
            <div className="flex items-center mr-2">
              <ProgressCircle percent={progress} />
            </div>
            <div>{uploadingText}</div>
          </div>
          <div className="w-full sm:w-80 bg-gray-200 rounded-full h-2.5 mt-3 overflow-hidden">
            <div
              className="bg-blue-500 h-2.5 rounded-full transition-all duration-500 ease-in-out"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
