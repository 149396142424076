import { useContext, useState } from "react";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import { ActionTiming, FeatureGateModalInfo, postFeatureUsage } from "./utils";
import ActionModal from "../../components/common/ActionModal/ActionModal";
import { useRouter } from "next/router";

export function useFeatureGating() {
  const router = useRouter();

  const { profileInfo, mutateFeatureUsage, featureLimits } =
    useContext(DesktopContext);
  const { is_test_price } = profileInfo || {};
  const [featureGateModalOpen, setFeatureGateModalOpen] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalSubheading, setModalSubheading] = useState("");
  const [modalIcon, setModalIcon] = useState("");

  const currentPlan = profileInfo?.current_plan?.type;

  async function updateFeatureUsage(slug: string, action: string) {
    if (is_test_price) {
      action = `${action}__DEC_TEST`;
    }

    const newFeatureUsage = await postFeatureUsage(slug, action, currentPlan);
    checkFeatureUsage(newFeatureUsage, action, ActionTiming.After);
    mutateFeatureUsage();
  }

  function checkIfProPlan() {
    if (currentPlan.includes("pro")) {
      return true;
    } else {
      return false;
    }
  }

  function checkFeatureUsage(
    featureUsageData: any,
    action: string,
    timing: ActionTiming
  ) {
    if (!currentPlan) {
      setFeatureGateState(action);
      return true;
    }

    if (is_test_price && !action.includes("TEST")) {
      action = `${action}__DEC_TEST`;
    }

    if (checkIfProPlan()) {
      return false;
    }

    const featureLimit = featureLimits?.[action]?.[currentPlan]?.["limit"];
    if (!featureLimit) {
      return false;
    }

    const featureLimitTimeline =
      featureLimits?.[action]?.[currentPlan]?.["timeline"];
    const whenToShowGate =
      featureLimits?.[action]?.[currentPlan]?.["whenToShowGate"];
    const showGateJustOnce =
      featureLimits?.[action]?.[currentPlan]?.["showGateJustOnce"];
    const currentUsage = featureUsageData?.[action]?.[featureLimitTimeline];

    if (
      currentUsage == featureLimit &&
      showGateJustOnce &&
      timing === whenToShowGate
    ) {
      setFeatureGateState(action);
      return true;
    }

    if (
      currentUsage >= featureLimit &&
      !showGateJustOnce &&
      timing === whenToShowGate
    ) {
      setFeatureGateState(action);
      return true;
    }

    return false;
  }

  function setFeatureGateState(action: string) {
    if (action.includes("__")) {
      action = action.split("__")[0];
    }

    let header, subheading, icon;

    if (!currentPlan) {
      const noPlanModalInfo = FeatureGateModalInfo.NO_PLAN;
      header = noPlanModalInfo.header;
      subheading = noPlanModalInfo.subheading;
      icon = noPlanModalInfo.icon;
    } else {
      header = FeatureGateModalInfo?.[action]?.[currentPlan]?.["header"];
      subheading =
        FeatureGateModalInfo?.[action]?.[currentPlan]?.["subheading"];
      icon = FeatureGateModalInfo?.[action]?.[currentPlan]?.["icon"];
    }

    setModalHeader(header);
    setModalSubheading(subheading);
    setModalIcon(icon);
    setFeatureGateModalOpen(true);
  }

  function renderFeatureGateModal() {
    return (
      <ActionModal
        open={featureGateModalOpen}
        setOpen={setFeatureGateModalOpen}
        header={modalHeader}
        subheader={modalSubheading}
        primaryButtonText="Manage Plan"
        primaryButtonFunction={() => {
          router.push("/settings/billing?editSubscription=true");
        }}
        secondaryButtonText="Maybe Later"
        secondaryButtonFunction={() => setFeatureGateModalOpen(false)}
        icon={modalIcon}
      />
    );
  }

  return {
    updateFeatureUsage,
    checkFeatureUsage,
    renderFeatureGateModal,
    checkIfProPlan,
    setFeatureGateState,
  };
}
