import { XIcon } from "@heroicons/react/outline";
import GalleryAsset from "../../../common/GalleryAsset/GalleryAsset";
import GrayLink from "../../../uiwrappers/GrayLink/GrayLink";
import IconButton from "../../../uiwrappers/IconButton/IconButton";
import LoadingSpinner from "../../../uiwrappers/LoadingSpinner/LoadingSpinner";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { useContext } from "react";
import { DuplicateIcon } from "@heroicons/react/solid";

export default function MobilePostButtons({
  startGenerateMedia,
  attachedPhotos,
  attachingPhoto,
  setAttachedPhotos,
  openAttachedPhotosModal,
  setSlideUpOpen,
}: any) {
  const { setIsDigitalAssetAttached } = useContext(DesktopContext);

  return (
    <div className="flex w-full space-x-2.5 pt-4">
      {!attachedPhotos.length && !attachingPhoto && (
        <>
          <div
            className="flex flex-grow items-center justify-between border border-blue-300 bg-blue-50 rounded-md overflow-hidden p-2 cursor-pointer"
            onClick={startGenerateMedia}
          >
            <div className="flex">
              <PrimaryLink
                text="Generate Media"
                icon="generateMedia"
                size="md"
                customStyle="whitespace-nowrap mr-2"
              />
            </div>
            <div>
              <IconButton size="xs" color="gray" icon="plus" />
            </div>
          </div>

          <div
            onClick={() => setSlideUpOpen(true)}
            className="flex flex-grow items-center justify-between border border-gray-300 bg-gray-50 rounded-md overflow-hidden p-2 cursor-pointer"
          >
            <div className="flex">
              <GrayLink
                text="Image/Video"
                size="md"
                customStyle="whitespace-nowrap mr-2"
              />
            </div>
            <div className="">
              <IconButton size="xs" color="gray" icon="plus" />
            </div>
          </div>
        </>
      )}

      {(attachedPhotos?.length > 0 || attachingPhoto) && (
        <div className="flex w-full">
          <div className="flex flex-grow w-full py-2.5 px-3 rounded-lg bg-blue-50 border border-blue-200 mr-4">
            <div className="flex justify-between w-full items-center">
              {attachingPhoto && (
                <div
                  style={{ width: 50, height: 50 }}
                  className="flex flex-shrink-0 justify-center items-center rounded-md bg-white border border-gray-200"
                >
                  <div className="flex justify-center items-center w-full h-full">
                    <LoadingSpinner color="blue" />
                  </div>
                </div>
              )}

              {attachedPhotos?.length > 0 && !attachingPhoto && (
                <>
                  <div className="flex items-center">
                    <div
                      className="relative flex flex-shrink-0 mr-3"
                      style={{ width: 50, height: 50 }}
                    >
                      {attachedPhotos?.length > 1 && (
                        <div className="absolute top-1 right-1">
                          <DuplicateIcon className="w-4 h-4 text-white" />
                        </div>
                      )}
                      <GalleryAsset
                        media={{
                          ...attachedPhotos[0],
                          data: attachedPhotos[0].url,
                        }}
                        className="flex flex-shrink-0 w-full h-full rounded-md object-cover w-full"
                      />
                    </div>
                    <div>
                      <div>
                        {attachedPhotos?.length} attachment
                        {attachedPhotos?.length > 1 ? "s" : ""}
                      </div>
                      <PrimaryLink
                        onClickFunc={openAttachedPhotosModal}
                        text="View"
                        size="lg"
                      />
                    </div>
                  </div>
                </>
              )}

              <XIcon
                onClick={() => {
                  setAttachedPhotos([]);
                  setIsDigitalAssetAttached(false);
                }}
                className="h-6 w-6 text-gray-600 rounded-full cursor-pointer"
              />
            </div>
          </div>

          <div className="flex w-1/4 h-full">
            <div className="flex justify-center items-center w-full">
              <IconButton
                size="xl"
                color="gray"
                icon="add-photo"
                onClickFunc={() => setSlideUpOpen(true)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
