import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { ModalActionButton } from "./ModalActionButton";

export interface SlideUpModalButton {
  type?: string;
  text?: string;
  icon?: string;
  customElement?: JSX.Element;
  customElementStyle?: string;
  onClickFunc?: () => void;
  children?: JSX.Element;
}

export default function SlideUpModal({
  isOpen,
  setIsOpen,
  header,
  headerIcon,
  buttons,
  children,
}: any) {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="sm:hidden fixed inset-x-0 bottom-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="sm:hidden fixed inset-x-0 bottom-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="opacity-0 translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transform ease-in duration-200 transition"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-full"
          >
            <div className="w-full overflow-hidden align-middle transition-all transform bg-white rounded-t-lg shadow-top">
              <div className="flex justify-between px-6 py-3 border-b">
                <div className="flex items-center">
                  {headerIcon}
                  <h3 className="text-body-medium text-gray-900">{header}</h3>
                </div>
                <div onClick={closeModal}>
                  <XIcon className="text-gray-500 h-6 w-6" />
                </div>
              </div>

              {children}

              <div className="flex flex-col w-full">
                {buttons.map((button: any, index: number) => {
                  if (button?.customElement) {
                    return (
                      <div
                        key={index}
                        className={button?.customElementStyle ?? "p-6 border-b"}
                      >
                        {button?.customElement}
                      </div>
                    );
                  }

                  return (
                    <div
                      key={index}
                      onClick={button?.onClickFunc}
                      className="p-6 border-b"
                    >
                      <ModalActionButton
                        text={button?.text}
                        icon={button?.icon}
                        type={button?.type}
                      />
                    </div>
                  );
                })}
              </div>

              <div className="w-full h-6"></div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
