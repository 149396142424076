import { useContext, useEffect, useState } from "react";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";
import AttachedPhotosDragDrop from "./AttachedPhotosDragDrop";
import { getOptimizedCloudFrontImageUrl, getS3File, isVideo } from "../utils";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { createFile } from "../BeforeAndAfterModal/utils";
import ActionModal from "../../../common/ActionModal/ActionModal";
import SlideUpModal from "../../../common/SlideUpModal/SlideUpModal";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import ImageUpload from "../../../uiwrappers/ImageUpload/ImageUpload";
import GrayLink from "../../../uiwrappers/GrayLink/GrayLink";
import GalleryAsset from "../../../common/GalleryAsset/GalleryAsset";
import useIsMobile from "../../../../hooks/useIsMobile";

export default function AttachedPhotosModal({
  attachedPhotosModalOpen,
  setAttachedPhotosModalOpen,
  closeAttachedPhotosModal,
  openPhotoSelectModal,
  startGenerateMedia,
  currentAttachedPhotos,
  setCurrentAttachedPhotos,
  infoType,
}: any) {
  const { attachedPhotos, setAttachedPhotos } = useContext(DesktopContext);

  const originalAttachedPhotos = [...attachedPhotos];
  const isMobile = useIsMobile();

  const [selectedPhoto, setSelectedPhoto] = useState(
    attachedPhotos?.length ? attachedPhotos[0] : null
  );
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [slideUpOpen, setSlideUpOpen] = useState(false);
  const [viewingPhoto, setViewingPhoto] = useState(null);

  useEffect(() => {
    if (attachedPhotosModalOpen) {
      selectPhoto(0);
    }
  }, [attachedPhotosModalOpen]);

  useEffect(() => {
    const photo = currentAttachedPhotos.some(
      (photo: any) => photo?.url === selectedPhoto?.url
    );
    if (!photo && currentAttachedPhotos?.length > 0) {
      selectPhoto(0);
    } else if (!photo && currentAttachedPhotos?.length === 0) {
      setSelectedPhoto(null);
    }
  }, [currentAttachedPhotos]);

  function selectPhoto(index) {
    const newPhotos = currentAttachedPhotos.map((photo, idx) => {
      if (index === idx) {
        setSelectedPhoto(photo);
        return { ...photo, selected: true };
      } else {
        return { ...photo, selected: false };
      }
    });

    setCurrentAttachedPhotos(newPhotos);
  }

  function cancelNewAttachments() {
    if (viewingPhoto && isMobile) {
      setViewingPhoto(null);
      return;
    }

    closeAttachedPhotosModal();
    setAttachedPhotos(originalAttachedPhotos);
    setCurrentAttachedPhotos(originalAttachedPhotos);
  }

  function saveNewAttachments() {
    closeAttachedPhotosModal();
    setAttachedPhotos(currentAttachedPhotos);
  }

  async function processImages(images: any) {
    setSlideUpOpen(false);
    const photosArray = [];

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      if (image.data === "loading") {
        return;
      }

      const file = await createFile(image.data, image.type);
      const signedRequest = await getS3File(file);

      const photoUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${signedRequest}`;
      photosArray.push({
        photo: photoUrl,
        data: photoUrl,
        url: photoUrl,
        type: image.type,
        name: image.name,
      });
    }

    setCurrentAttachedPhotos([...currentAttachedPhotos, ...photosArray]);
  }

  let slideUpModalButtons = [
    {
      onClickFunc: () => null,
      customElement: (
        <div onClick={() => startGenerateMedia()}>
          <PrimaryLink
            icon="generateMedia"
            iconPlacement="leading"
            text="Generate Media"
            size="md"
          />
        </div>
      ),
    },
    {
      onClickFunc: () => null,
      customElement: (
        <div
          onClick={() => {
            openPhotoSelectModal("attachedPhotosModal");
            setSlideUpOpen(false);
          }}
        >
          <GrayLink
            icon="photograph"
            iconPlacement="leading"
            text="Select from Gallery..."
            size="md"
          />
        </div>
      ),
    },
    {
      onClickFunc: () => null,
      customElement: (
        <ImageUpload multiple={true} processImages={processImages}>
          <GrayLink
            icon="upload"
            iconPlacement="leading"
            text="Add from Camera Roll"
            size="md"
            customStyle="flex h-full w-full"
          />
        </ImageUpload>
      ),
    },
  ];

  if (infoType === "showcase") {
    slideUpModalButtons = slideUpModalButtons.slice(1);
  }

  function getHeader() {
    if (infoType === "showcase") {
      return "Manage Showcase Media";
    }
    return "Post Attachments";
  }

  return (
    <UtilityModal
      isOpen={attachedPhotosModalOpen}
      setIsOpen={() => setIsExitModalOpen(true)}
      onCancelHandler={cancelNewAttachments}
      title={getHeader()}
      width="sm:w-800"
      disablePadding={true}
      submitButtonText="Save"
      onSubmitHandler={saveNewAttachments}
      modalBodyBackground="bg-white"
      headerActionButton={!viewingPhoto}
      headerActionButtonText="Add"
      headerActionButtonFunction={() => setSlideUpOpen(true)}
      headerActionButtonColor={"blue"}
    >
      {(!viewingPhoto || !isMobile) && (
        <div className="flex sm:-mx-6 sm:h-500">
          <div className="flex w-full">
            <div className="flex flex-col w-full sm:w-1/2 h-full bg-white p-4 overflow-auto">
              <AttachedPhotosDragDrop
                photos={currentAttachedPhotos}
                setPhotos={setCurrentAttachedPhotos}
                openPhotoSelectModal={openPhotoSelectModal}
                processImages={processImages}
                startGenerateMedia={startGenerateMedia}
                setViewingPhoto={setViewingPhoto}
                selectPhoto={selectPhoto}
                infoType={infoType}
              />
            </div>
            <div className="hidden sm:flex items-center w-1/2 bg-gray-100 p-4">
              {selectedPhoto && (
                <div className="flex w-full h-full items-center justify-center">
                  {isVideo(selectedPhoto) ? (
                    <div className="w-full h-full">
                      <video
                        autoPlay
                        muted
                        className="w-full h-full object-scale-down"
                      >
                        <source
                          src={selectedPhoto?.data ?? selectedPhoto?.photo}
                        />
                      </video>
                    </div>
                  ) : (
                    <img
                      className="w-full h-full object-contain"
                      src={getOptimizedCloudFrontImageUrl(
                        selectedPhoto?.data ?? selectedPhoto?.photo
                      )}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {viewingPhoto && isMobile && (
        <div className="w-full h-auto">
          <GalleryAsset className="w-full h-auto" media={viewingPhoto} />
        </div>
      )}

      <SlideUpModal
        isOpen={slideUpOpen}
        setIsOpen={setSlideUpOpen}
        header="Add Options"
        buttons={slideUpModalButtons}
      />

      <ActionModal
        open={isExitModalOpen}
        setOpen={setIsExitModalOpen}
        header="Are you sure?"
        subheader="Your changes will be lost"
        primaryButtonText="Leave"
        secondaryButtonText="Stay here"
        primaryButtonFunction={() => {
          setAttachedPhotosModalOpen(false);
          setIsExitModalOpen(false);
          cancelNewAttachments();
        }}
        secondaryButtonFunction={() => setIsExitModalOpen(false)}
      />
    </UtilityModal>
  );
}
