import TextareaAutosize from "react-textarea-autosize";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useEffect, useState } from "react";

export default function PostTextarea({
  newMessage,
  handleChange,
  getTextAreaPlaceholder,
  textareaRef,
  attachedPhotos,
}: any) {
  const isMobile = useIsMobile();
  const [maxRows, setMaxRows] = useState<any>(null);

  function calculateMaxRows() {
    const heightOfScreen = window.innerHeight;
    const rowHeight = 24;

    let nonTextareaHeight = 316;
    if (attachedPhotos?.length > 0) {
      nonTextareaHeight = 508;
    }

    let newMaxRows = maxRows;
    newMaxRows =
      Math.floor((heightOfScreen - nonTextareaHeight) / rowHeight) - 1;

    if (!!newMaxRows && newMaxRows !== maxRows) {
      setMaxRows(newMaxRows);
    }
  }

  function handleResize() {
    if (textareaRef.current) {
      const height = textareaRef?.current?.style?.height || 330;
      const rows = textareaRef?.current?.rows;
      if (height && rows) {
        calculateMaxRows();
      }
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [attachedPhotos, newMessage]);

  return (
    <div className="flex justify-center w-full h-full">
      {isMobile && (
        <textarea
          name="post-input"
          value={newMessage}
          onChange={handleChange}
          className="bg-white flex-1 border-0 w-full text-body-normal text-gray-900 focus:ring-0 p-0"
          placeholder={getTextAreaPlaceholder()}
          style={{ resize: "none" }}
        />
      )}
      {!isMobile && (
        <TextareaAutosize
          ref={textareaRef}
          name="post-input"
          value={newMessage}
          onChange={handleChange}
          className="bg-white h-full border-none w-full text-body-normal text-gray-900 focus:ring-0 resize-none p-0"
          placeholder={getTextAreaPlaceholder()}
          minRows={9}
          maxRows={maxRows}
          onHeightChange={calculateMaxRows}
        />
      )}
    </div>
  );
}
