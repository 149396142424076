import React, { useContext, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import Calendar from "../Calendar/Calendar";
import {
  schedulePost,
  getNextHourAndMinutes,
  getDayOfOneHourFromNow,
} from "../utils";
import { format, isEqual } from "date-fns";
import moment from "moment";
import "moment-timezone";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import useChurnModal from "../../../../hooks/useChurnModal/useChurnModal";

const SchedulePostModal = ({
  message,
  isOpen,
  setIsOpen,
  pullPosts,
  toggleFacebookStatus,
  toggleGoogleStatus,
  toggleInstagramStatus,
  setMessage,
  attachedPhotos,
  setAttachedPhotos,
  setCreatePostModalOpen,
  setFromSchedulePost,
}: any) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const date = new Date();
  const timezone = date.toString().split("(")[1].replace(")", "");

  const [selectedDate, setSelectedDate] = useState(
    getDayOfOneHourFromNow(today)
  );
  const [selectedTime, setSelectedTime] = useState(
    getNextHourAndMinutes(today)
  );
  const [datepickerHeaderDate, setDatepickerHeaderDate] = useState(new Date());
  const [disabled, setDisabled] = useState<boolean>(false);
  const { basicInfo } = useContext(DesktopContext);
  const { renderChurnModal, shouldOpenChurnModal } = useChurnModal();
  const { sitePk } = basicInfo || {};
  const [error, setError] = useState("");

  const router = useRouter();

  function isToday(date: number) {
    const formattedDate = new Date(selectedDate);
    return isEqual(
      new Date(formattedDate.getFullYear(), formattedDate.getMonth(), date),
      formattedDate
    );
  }

  function setDateValue(date) {
    const newDate = new Date(
      datepickerHeaderDate.getFullYear(),
      datepickerHeaderDate.getMonth(),
      date
    );
    const formattedDate = format(newDate, "MMM d, yyyy");
    setSelectedDate(formattedDate);
  }

  function handleChangeTime(event) {
    setSelectedTime(event.target.value);
  }

  async function onSubmitHandler() {
    if (shouldOpenChurnModal()) {
      setIsOpen(false);
      setFromSchedulePost(true);
      return;
    }

    setCreatePostModalOpen(false);
    setDisabled(true);

    const hour = parseInt(selectedTime.split(":")[0]);
    const minute = parseInt(selectedTime.split(":")[1]);
    const date = new Date(selectedDate);
    date.setHours(hour);
    date.setMinutes(minute);

    const isoDate = moment(date).local().format("YYYY-MM-DDTHH:mm:ssZZ");
    const time = { date: isoDate };

    let fbScheduleResponse;
    let googleScheduleResponse;
    let igScheduleResponse;

    const photoLinks = attachedPhotos.map((photo) => {
      return photo?.url;
    });

    if (toggleFacebookStatus) {
      fbScheduleResponse = await schedulePost(
        time,
        message,
        sitePk,
        photoLinks,
        "facebook"
      );
    }
    if (toggleGoogleStatus) {
      googleScheduleResponse = await schedulePost(
        time,
        message,
        sitePk,
        photoLinks,
        "google"
      );
    }
    if (toggleInstagramStatus) {
      igScheduleResponse = await schedulePost(
        time,
        message,
        sitePk,
        photoLinks,
        "instagram"
      );
    }

    // check if the toggled socials responded with a success status
    if (
      ((fbScheduleResponse && fbScheduleResponse.status === "success") ||
        !fbScheduleResponse) &&
      ((googleScheduleResponse &&
        googleScheduleResponse.status === "success") ||
        !googleScheduleResponse) &&
      ((igScheduleResponse && igScheduleResponse.status === "success") ||
        !igScheduleResponse)
    ) {
      toast.success("Post scheduled");
    } else {
      toast.error("Unable to schedule post. Please try again.");
    }

    pullPosts();
    setMessage("");
    setAttachedPhotos([]);
    setCreatePostModalOpen(false);
    setIsOpen(false);
    setDisabled(false);
  }

  function onCancelHandler() {
    setIsOpen(false);
    setCreatePostModalOpen(true);
  }

  return (
    <div>
      {renderChurnModal()}
      <UtilityModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Schedule Post"
        showCancelButton={true}
        showSubmitButton={true}
        submitButtonText="Schedule"
        submitButtonId="edit-post-submit"
        isSubmitDisabled={disabled}
        onSubmitHandler={onSubmitHandler}
        onCancelHandler={onCancelHandler}
        showLoader={true}
        width="sm:w-700"
      >
        <section className="flex flex-col w-full h-full">
          <div className="text-gray-500 w-full text-center sm:text-left">
            {timezone}
          </div>
          <Calendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedTime={selectedTime}
            handleChangeTime={handleChangeTime}
            setDateValue={setDateValue}
            isToday={isToday}
            datepickerHeaderDate={datepickerHeaderDate}
            setDatepickerHeaderDate={setDatepickerHeaderDate}
            setDisabled={setDisabled}
            setError={setError}
            error={error}
          />
        </section>
      </UtilityModal>
    </div>
  );
};

export default SchedulePostModal;
