import { FacebookGroupPost, FacebookGroupSuggestions } from "./types";
import { api } from "../../../../helpers/topline-api";

export async function getFacebookGroupPosts(): Promise<FacebookGroupPost[]> {
  try {
    const response = await api.get(`/api/social-get-facebook-group-posts`);
    return response?.data?.posts ?? [];
  } catch (e) {
    console.log("Error getting facebook group posts:", e);
    return [];
  }
}

export async function getFacebookGroupSuggestions(
  landingPage: string
): Promise<FacebookGroupSuggestions[]> {
  try {
    const response = await api.get(
      `/api/social-get-facebook-group-suggestions/` + landingPage
    );

    return response?.data?.suggestions ?? [];
  } catch (e) {
    console.log("Error getting facebook group suggestions:", e);
    return [];
  }
}

export async function hideFacebookSuggestion(groupID: string) {
  try {
    const response = await api.post(
      `/api/social-hide-facebook-group-suggestion`,
      { group_id: groupID }
    );

    return response?.data?.success ?? false;
  } catch (e) {
    console.log("Error hiding facebook suggestion:", e);
    return false;
  }
}

export async function createFacebookGroupPost(
  facebookGroupID: string,
  content: string,
  attachedMediaURLs: string[]
): Promise<FacebookGroupPost> {
  try {
    const data = {
      group_id: facebookGroupID,
      content: content,
      media_urls: attachedMediaURLs,
    };

    const response = await api.post(
      `/api/social-create-facebook-group-post`,
      data
    );

    return response?.data ?? ({} as FacebookGroupPost);
  } catch (e) {
    console.log("Error creating new group post", e);
    return {} as FacebookGroupPost;
  }
}

export async function editFacebookGroupPost(
  postID: string,
  facebookGroupID: string,
  content: string,
  attachedMediaURLs: string[]
): Promise<FacebookGroupPost> {
  try {
    const data = {
      post_id: postID,
      group_id: facebookGroupID,
      content: content,
      media_urls: attachedMediaURLs,
    };

    const response = await api.post(
      `/api/social-edit-facebook-group-post`,
      data
    );

    return response?.data ?? ({} as FacebookGroupPost);
  } catch (e) {
    console.log("Error editing group post", e);
    return {} as FacebookGroupPost;
  }
}

export async function deleteFacebookGroupPost(
  postID: string
): Promise<boolean> {
  try {
    const data = {
      post_id: postID,
    };

    const response = await api.post(
      `/api/social-delete-facebook-group-post`,
      data
    );

    return response?.data?.deleted ?? false;
  } catch (e) {
    console.log("Error deleting group post", e);
    return false;
  }
}

export async function generateGroupIntroPost() {
  try {
    const response = await api.post(`/api/generate-facebook-group-post`, {
      is_intro_post: true,
    });

    return response?.data?.content ?? "";
  } catch (e) {
    console.log("Error generating welcome post", e);
    return "";
  }
}
