const ProgressCircle = ({ percent }: any) => {
  const radius = 8;
  const circumference = 2 * Math.PI * radius;
  const strokeDashOffset = circumference - (percent / 100) * circumference;

  return (
    <div className="relative flex items-center justify-center">
      <svg className="flex transform -rotate-90 w-6 h-6">
        <circle
          cx="12"
          cy="12"
          r={radius}
          stroke="currentColor"
          strokeWidth="2.5"
          fill="transparent"
          className="text-blue-300"
        />
        <circle
          cx="12"
          cy="12"
          r={radius}
          stroke="currentColor"
          strokeWidth="2.5"
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashOffset}
          className="text-blue-500 transition-all duration-500 ease-in-out"
        />
      </svg>
    </div>
  );
};

export default ProgressCircle;
