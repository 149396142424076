import React, { useContext, useEffect, useRef, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";
import GeneratingPost from "./GeneratingPost";
import PostTextarea from "./PostTextarea";
import { useRouter } from "next/router";
import DesktopAttachedPhotos from "./DesktopAttachedPhotos";
import DesktopPhotosButtons from "./DesktopPhotosButtons";
import { getOneRandomTopic } from "../../marketing/DigitalMedia/utils";
import useIsMobile from "../../../../hooks/useIsMobile";
import PostButtons from "./PostButtons";
import { getPathname } from "./utils";
import {
  ActionTiming,
  Features,
} from "../../../../hooks/useFeatureGating/utils";
import { useFeatureGating } from "../../../../hooks/useFeatureGating/useFeatureGating";
import AttachedPhotosModal from "./AttachedPhotosModal";
import ImageUpload from "../../../uiwrappers/ImageUpload/ImageUpload";
import GrayLink from "../../../uiwrappers/GrayLink/GrayLink";
import SlideUpModal, {
  SlideUpModalButton,
} from "../../../common/SlideUpModal/SlideUpModal";
import PhotoSelectModalSocial from "../../../uiwrappers/PhotoSelectModal/PhotoSelectModalSocial";
import ActionModal from "../../../common/ActionModal/ActionModal";
import { handleDownload } from "../GroupPost/GroupPost";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { DocumentDuplicateIcon } from "@heroicons/react/outline";
import { SocialContentError } from "../../../../hooks/useNewPostModal/SocialContentError";
import { validateSocialContent } from "../../../../hooks/useNewPostModal/validateSocialContent";

export default function CreateAPostModal({
  newMessage,
  setNewMessage,
  handleChange,
  attachedPhotos,
  setAttachedPhotos,
  renderSyncPills,
  renderSyncEditPostPills,
  attachingPhoto,
  openScheduleModal,
  loading,
  makePost,
  createPostModalOpen,
  setCreatePostModalOpen,
  generatingPost,
  disabled,
  editingPost,
  setEditingPost,
  noScheduling,
  currentPlan,
  toggleFacebookStatus,
  toggleGoogleStatus,
  toggleInstagramStatus,
  setEditPostToggleFacebookStatus,
  setEditPostToggleGoogleStatus,
  handleGeneratePost,
  rewritePostErrorMessage,
  setRewritePostErrorMessage,
  handleRewriteForMe,
  postToEditIds,
  setGeneratingPost,
  processImages,
  slideUpOpen,
  setSlideUpOpen,
  isGroup,
  children,
}: any) {
  const isMobile = useIsMobile();
  const {
    userPhoto,
    reviewInfo,
    userPersonalBlogs,
    userScheduleBlogs,
    basicInfo,
    profileInfo,
    setSavedPost,
    featureUsage,
    socialContentError,
    setSocialContentError,
    setIsDigitalAssetAttached,
  } = useContext(DesktopContext);

  const { renderFeatureGateModal, checkFeatureUsage } = useFeatureGating();

  const router = useRouter();

  const [previousModal, setPreviousModal] = useState("");
  const [photoSelectModalOpen, setPhotoSelectModalOpen] = useState(false);
  const [attachedPhotosModalOpen, setAttachedPhotosModalOpen] = useState(false);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isGroupPostDoneModalOpen, setIsGroupPostDoneModalOpen] =
    useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [error, setError] = useState<any>("");
  const textareaRef = useRef(null);

  const [currentAttachedPhotos, setCurrentAttachedPhotos] = useState([
    ...attachedPhotos,
  ]);
  const [copiedText, setCopiedText] = useState("");

  useEffect(() => {
    setRewritePostErrorMessage("");

    if (!createPostModalOpen) {
      setGeneratingPost(false);
    }
  }, [createPostModalOpen]);

  useEffect(() => {
    if (
      basicInfo?.is_social_footer_active &&
      basicInfo?.social_post_footer &&
      !newMessage
    ) {
      setNewMessage("\n\n" + basicInfo?.social_post_footer);
    }
  }, [createPostModalOpen]);

  useEffect(() => {
    if (toggleFacebookStatus || toggleGoogleStatus || toggleInstagramStatus) {
      setError("");
    }
  }, [toggleFacebookStatus, toggleGoogleStatus, toggleInstagramStatus]);

  useEffect(() => {
    if (postToEditIds?.facebook) {
      setEditPostToggleFacebookStatus(true);
    } else {
      setEditPostToggleFacebookStatus(false);
    }

    if (postToEditIds?.google) {
      setEditPostToggleGoogleStatus(true);
    } else {
      setEditPostToggleGoogleStatus(false);
    }
  }, [postToEditIds, createPostModalOpen]);

  useEffect(() => {
    setCurrentAttachedPhotos(attachedPhotos);
  }, [attachedPhotos]);

  function closeModal() {
    if (
      !isGroup &&
      editingPost &&
      !toggleFacebookStatus &&
      !toggleGoogleStatus &&
      (currentPlan.type === "pro" || currentPlan.type === "core")
    ) {
      setError("Please select Facebook or Google.");
      return;
    }

    setNewMessage("");
    setAttachedPhotos([]);
    setCreatePostModalOpen(false);
    setEditingPost(false);
    setIsDigitalAssetAttached(false);
    setIsExitModalOpen(false);
    setIsGroupPostDoneModalOpen(false);
  }

  function getTextAreaPlaceholder() {
    const placeholderMessage = "Write your post here...";
    if (generatingPost) {
      return "";
    }

    return placeholderMessage;
  }

  function startGenerateMedia() {
    const hasHitLimit = checkFeatureUsage(
      featureUsage,
      Features.DIGITAL_MEDIA_CREATE,
      ActionTiming.Before
    );
    if (hasHitLimit) {
      return;
    }

    setSavedPost({
      text: newMessage,
      photos: attachedPhotos,
    });

    const topic = getOneRandomTopic(
      userPhoto,
      reviewInfo,
      userPersonalBlogs,
      userScheduleBlogs,
      basicInfo
    );

    const origin = getPathname(router);
    router.push({
      pathname: "/marketing/digital-media-create",
      query: {
        origin,
        postModalOpen: createPostModalOpen || attachedPhotosModalOpen,
        topic,
      },
    });
  }

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(copiedText);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Message will be visible for 2 seconds
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const handleGroupPostSave = () => {
    const error = validateSocialContent(
      true,
      false,
      false,
      false,
      newMessage,
      attachedPhotos
    );

    if (error) {
      setSocialContentError(error);
      return;
    }

    makePost();
    setCopiedText(newMessage);
    closeModal();
    setIsGroupPostDoneModalOpen(true);
  };

  const elementStickyToBottom = (
    <div className="flex flex-col sm:flex-row w-full">
      <div className="flex justify-end items-center w-full sm:py-0 sm:pt-6 sm:relative z-50">
        <div
          className={
            isGroup && isMobile
              ? "flex flex-row-reverse items-center justify-between w-full"
              : "flex items-center flex-row-reverse justify-between sm:justify-start min-h-full sm:flex-grow"
          }
        >
          <PrimaryButton
            id="create-post-button"
            text={editingPost || isGroup ? "Save" : "Post"}
            size="md"
            onClickFunc={isGroup ? handleGroupPostSave : makePost}
            loading={loading}
            customStyle={"w-full sm:w-20 flex"}
            disabled={loading}
          />
          {isGroup && (
            <SecondaryButton
              text="Cancel"
              size="md"
              onClickFunc={closeModal}
              customStyle="w-full flex sm:w-20 sm:mt-0 text-body-normal mr-2 sm:mr-3"
            />
          )}
          {!noScheduling && !isGroup && (
            <PrimaryLink
              text="Schedule Post"
              size="lg"
              onClickFunc={openScheduleModal}
              customStyle="mt-3 sm:mt-0 text-body-normal sm:mr-3"
              saveDisabled={disabled}
            />
          )}
        </div>
      </div>
    </div>
  );

  function getHeader() {
    if (editingPost) {
      return "Edit Post";
    } else if (isGroup) {
      return "Create Group Post";
    } else {
      return "Create Post";
    }
  }

  function selectPhotosForPost(selectedPhotos: any[]) {
    if (previousModal === "attachedPhotosModal") {
      const updatedAttachedPhotos = [
        ...currentAttachedPhotos,
        ...selectedPhotos,
      ];
      const newUniquePhotos = updatedAttachedPhotos.filter(
        (photo, index, array) =>
          !photo.id || array.findIndex((p) => p.id === photo.id) === index
      );

      setCurrentAttachedPhotos(newUniquePhotos);
      setPhotoSelectModalOpen(false);
      setAttachedPhotosModalOpen(true);
    } else {
      const updatedAttachedPhotos = [...attachedPhotos, ...selectedPhotos];
      const newUniquePhotos = updatedAttachedPhotos.filter(
        (photo, index, array) =>
          !photo.id || array.findIndex((p) => p.id === photo.id) === index
      );

      setCurrentAttachedPhotos(newUniquePhotos);
      setAttachedPhotos(newUniquePhotos);
      setPhotoSelectModalOpen(false);
      setCreatePostModalOpen(true);
    }
  }

  function openPhotoSelectModal(from: string) {
    setPreviousModal(from);
    setPhotoSelectModalOpen(true);
    setCreatePostModalOpen(false);
    setAttachedPhotosModalOpen(false);
  }

  function closePhotoSelectModal() {
    setPreviousModal("");
    setPhotoSelectModalOpen(false);

    if (previousModal === "attachedPhotosModal") {
      setAttachedPhotosModalOpen(true);
    } else {
      setCreatePostModalOpen(true);
    }
  }

  function openAttachedPhotosModal(from: string) {
    setPreviousModal(editingPost ? "editPostModal" : "createPostModal");

    setAttachedPhotosModalOpen(true);
    setCreatePostModalOpen(false);
    setPhotoSelectModalOpen(false);
  }

  function closeAttachedPhotosModal() {
    setPreviousModal("");
    setAttachedPhotosModalOpen(false);
    setCreatePostModalOpen(true);
  }

  const GoToFacebookButton = ({ stepNumber }: { stepNumber: number }) => {
    return (
      <div className="p-6 w-full flex justify-between items-center sm:border-b">
        <div className="text-blue-800 bg-blue-100 py-0.5 px-2.5 rounded-full text-xs font-medium">
          STEP {stepNumber}
        </div>
        <a
          className="text-white bg-blue-900 pr-4 pl-5 py-2 rounded-full text-sm font-medium justify-center items-center gap-2 flex whitespace-nowrap"
          href="https://www.facebook.com/groups/joins"
          target="_blank"
        >
          <span>Go to Facebook</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M8.3335 5.00065H5.00016C4.07969 5.00065 3.3335 5.74684 3.3335 6.66732V15.0007C3.3335 15.9211 4.07969 16.6673 5.00016 16.6673H13.3335C14.254 16.6673 15.0002 15.9211 15.0002 15.0007V11.6673M11.6668 3.33398H16.6668M16.6668 3.33398V8.33398M16.6668 3.33398L8.3335 11.6673"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
    );
  };

  const GroupPostFinalSteps = () => {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="flex justify-center w-full px-6 py-2.5 text-base font-semibold border-b">
          Share your post in Facebook groups
        </div>
        <div className="p-6 w-full flex justify-between items-center border-b">
          <div className="text-blue-800 bg-blue-100 py-0.5 px-2.5 rounded-full text-xs font-medium">
            STEP 1
          </div>
          <div
            className="text-blue-600 text-sm font-medium justify-center items-center gap-2 flex cursor-pointer"
            onClick={handleCopyClick}
          >
            <DocumentDuplicateIcon className="w-5 h-5" />
            <span className="whitespace-nowrap cursor-pointer">
              {isCopied ? "Copied!" : "Copy Post Text"}
            </span>
          </div>
        </div>
        {attachedPhotos?.length > 0 && (
          <div className="p-6 w-full flex justify-between items-center border-b">
            <div className="text-blue-800 bg-blue-100 py-0.5 px-2.5 rounded-full text-xs font-medium">
              STEP 2
            </div>
            <span
              className="text-blue-600 text-sm font-medium cursor-pointer"
              onClick={() =>
                handleDownload(
                  attachedPhotos?.map((attachedPhoto: any) => ({
                    data: attachedPhoto.photo,
                  }))
                )
              }
            >
              Download Attachments
            </span>
          </div>
        )}
        <GoToFacebookButton stepNumber={attachedPhotos?.length > 0 ? 3 : 2} />
      </div>
    );
  };

  const slideUpModalButtons: SlideUpModalButton[] = [
    {
      onClickFunc: () => null,
      customElement: (
        <div
          onClick={(e) => {
            startGenerateMedia();
          }}
        >
          <PrimaryLink
            icon="generateMedia"
            iconPlacement="leading"
            text="Generate Media"
            size="md"
          />
        </div>
      ),
    } as SlideUpModalButton,
    {
      onClickFunc: () => null,
      customElement: (
        <ImageUpload multiple={true} processImages={processImages}>
          <GrayLink
            icon="upload"
            iconPlacement="leading"
            text="Add from Camera Roll"
            size="md"
            customStyle="flex h-full w-full"
          />
        </ImageUpload>
      ),
    } as SlideUpModalButton,
    {
      text: "Select from Gallery...",
      onClickFunc: () => {
        openPhotoSelectModal("createPostModal");
        setSlideUpOpen(false);
      },
    } as SlideUpModalButton,
  ];

  const slideUpGroupModalButtons = [
    {
      onClickFunc: () => null,
      customElement: <GroupPostFinalSteps />,
      customElementStyle: "border-b",
    } as SlideUpModalButton,
  ];

  function getSlideUpModalButtons(): SlideUpModalButton[] {
    if (attachedPhotos?.length) {
      return slideUpModalButtons;
    }
    return [slideUpModalButtons[1], slideUpModalButtons[2]];
  }

  return (
    <>
      <UtilityModal
        title={getHeader()}
        fullWidth
        isOpen={createPostModalOpen}
        setIsOpen={() => setIsExitModalOpen(true)}
        onCancelHandler={closeModal}
        showCancelButton={false}
        showSubmitButton={false}
        headerActionButton
        headerActionButtonText="Post"
        headerActionButtonFunction={makePost}
        disabledActionButton={disabled}
        loading={loading}
        headerBorder={true}
        footerBorder={true}
        elementStickyToBottom={elementStickyToBottom}
        width="sm:w-800"
        modalBodyBackground="bg-white"
        customOverflow="sm:overflow-y-auto"
        disablePadding={true}
      >
        {renderFeatureGateModal()}

        <div
          id="create-a-post-section"
          className="flex flex-col flex-grow h-full overflow-auto sm:-mx-6 bg-white py-4 sm:p-0"
        >
          <div className="flex flex-col h-full sm:px-6 sm:py-4 px-4 py-0">
            <div className="flex flex-col h-full w-full sm:px-0 overflow-visible relative">
              {!isGroup && (
                <div className="flex flex-nowrap items-center top-0 z-50">
                  <div
                    style={{
                      width: isMobile ? 40 : 52,
                      height: isMobile ? 40 : 52,
                    }}
                    className="relative flex flex-shrink-0 rounded-full object-cover sm:mr-3 mr-2.5"
                  >
                    {basicInfo?.logo && (
                      <img
                        src={basicInfo.logo}
                        alt="logo"
                        className="w-full rounded-full object-cover"
                      />
                    )}
                  </div>

                  <div className="space-y-1">
                    <p className="text-sm-medium sm:text-body-medium">
                      {profileInfo?.company_name}
                    </p>
                    {editingPost && renderSyncEditPostPills()}
                    {!editingPost && renderSyncPills()}
                    {editingPost && error && (
                      <div className="text-sm-normal text-red-600">{error}</div>
                    )}
                  </div>
                </div>
              )}

              <div
                style={!isMobile ? { gridTemplateColumns: "1fr 180px" } : {}}
                className="sm:grid sm:gap-4 w-full sm:h-auto h-full pt-4"
              >
                <div className="flex flex-col w-full sm:h-auto h-full">
                  {generatingPost && <GeneratingPost />}

                  {!generatingPost && (
                    <PostTextarea
                      newMessage={newMessage}
                      handleChange={handleChange}
                      getTextAreaPlaceholder={getTextAreaPlaceholder}
                      textareaRef={textareaRef}
                      attachedPhotos={attachedPhotos}
                    />
                  )}

                  <PostButtons
                    toggleGoogleStatus={toggleGoogleStatus}
                    toggleFacebookStatus={toggleFacebookStatus}
                    toggleInstagramStatus={toggleInstagramStatus}
                    handleGeneratePost={handleGeneratePost}
                    currentPlan={currentPlan}
                    handleRewriteForMe={handleRewriteForMe}
                    rewritePostErrorMessage={rewritePostErrorMessage}
                    startGenerateMedia={startGenerateMedia}
                    processImages={processImages}
                    attachedPhotos={attachedPhotos}
                    attachingPhoto={attachingPhoto}
                    setAttachedPhotos={setAttachedPhotos}
                    openAttachedPhotosModal={openAttachedPhotosModal}
                    openPhotoSelectModal={openPhotoSelectModal}
                    slideUpOpen={slideUpOpen}
                    setSlideUpOpen={setSlideUpOpen}
                    isGroupPost={isGroup}
                  />
                  <SocialContentError error={socialContentError} />
                </div>

                {!isMobile &&
                  (attachedPhotos?.length > 0 || attachingPhoto) && (
                    <DesktopAttachedPhotos
                      setRewritePostErrorMessage={setRewritePostErrorMessage}
                      attachingPhoto={attachingPhoto}
                      openAttachedPhotosModal={openAttachedPhotosModal}
                      openPhotoSelectModal={openPhotoSelectModal}
                      processImages={processImages}
                      startGenerateMedia={startGenerateMedia}
                      toggleFacebookStatus={toggleFacebookStatus}
                      toggleGoogleStatus={toggleGoogleStatus}
                      toggleInstagramStatus={toggleInstagramStatus}
                      isGroupPost={isGroup}
                    />
                  )}

                {!isMobile &&
                  attachedPhotos?.length === 0 &&
                  !attachingPhoto && (
                    <DesktopPhotosButtons
                      processImages={processImages}
                      startGenerateMedia={startGenerateMedia}
                      openAttachedPhotosModal={openAttachedPhotosModal}
                      openPhotoSelectModal={openPhotoSelectModal}
                      toggleFacebookStatus={toggleFacebookStatus}
                      toggleGoogleStatus={toggleGoogleStatus}
                      toggleInstagramStatus={toggleInstagramStatus}
                      isGroupPost={isGroup}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>

        {children}

        <SlideUpModal
          isOpen={slideUpOpen}
          setIsOpen={setSlideUpOpen}
          header="Add Options"
          buttons={getSlideUpModalButtons()}
        />
      </UtilityModal>

      <ActionModal
        open={isExitModalOpen}
        setOpen={setIsExitModalOpen}
        header="Are you sure?"
        subheader="Your changes will be lost"
        primaryButtonText="Leave"
        secondaryButtonText="Stay here"
        primaryButtonFunction={() => closeModal()}
        secondaryButtonFunction={() => setIsExitModalOpen(false)}
      />

      {isGroup && isMobile ? (
        <SlideUpModal
          isOpen={isGroupPostDoneModalOpen}
          setIsOpen={setIsGroupPostDoneModalOpen}
          header="Post Saved!"
          headerIcon={
            <CheckCircleIcon className="flex w-5 h-5 text-green-600 mr-1.5" />
          }
          buttons={slideUpGroupModalButtons}
        />
      ) : (
        <UtilityModal
          isOpen={isGroupPostDoneModalOpen}
          setIsOpen={setIsGroupPostDoneModalOpen}
          title=""
          onCancelHandler={closeModal}
          submitButtonText="Save"
          showCancelButton={false}
          showSubmitButton={false}
          showLoader={true}
        >
          <div className="flex flex-col gap-4">
            <div className="w-full flex justify-center items-center gap-1.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5 18C14.9183 18 18.5 14.4183 18.5 10C18.5 5.58172 14.9183 2 10.5 2C6.08172 2 2.5 5.58172 2.5 10C2.5 14.4183 6.08172 18 10.5 18ZM14.2071 8.70711C14.5976 8.31658 14.5976 7.68342 14.2071 7.29289C13.8166 6.90237 13.1834 6.90237 12.7929 7.29289L9.5 10.5858L8.2071 9.29289C7.81658 8.90237 7.18342 8.90237 6.79289 9.29289C6.40237 9.68342 6.40237 10.3166 6.79289 10.7071L8.79289 12.7071C9.18342 13.0976 9.81658 13.0976 10.2071 12.7071L14.2071 8.70711Z"
                  fill="#059669"
                />
              </svg>
              <span className="font-medium">Post saved!</span>
            </div>
            <span className="text-gray-900 font-semibold text-lg whitespace-nowrap w-94 sm:mx-20">
              Share your post in Facebook groups
            </span>
            <GroupPostFinalSteps />
          </div>
        </UtilityModal>
      )}

      <AttachedPhotosModal
        infoType="social"
        attachedPhotosModalOpen={attachedPhotosModalOpen}
        setAttachedPhotosModalOpen={setAttachedPhotosModalOpen}
        closeAttachedPhotosModal={closeAttachedPhotosModal}
        openPhotoSelectModal={openPhotoSelectModal}
        startGenerateMedia={startGenerateMedia}
        currentAttachedPhotos={currentAttachedPhotos}
        setCurrentAttachedPhotos={setCurrentAttachedPhotos}
      />

      <PhotoSelectModalSocial
        header="Select from Gallery"
        isOpen={photoSelectModalOpen}
        setIsOpen={setPhotoSelectModalOpen}
        onSaveHandler={selectPhotosForPost}
        onCancelHandler={closePhotoSelectModal}
        primaryButtonText="Add to Post"
        showNumberPhotosSelected={true}
      />
    </>
  );
}
