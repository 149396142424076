import {
  isVideo,
  isVideoUrl,
} from "../../components/primaryrender/editor/utils";
import {
  FACEBOOK_PHOTO_VIDEO_ERROR,
  FACEBOOK_GROUPS_PHOTO_VIDEO_ERROR,
  GENERAL_PHOTO_VIDEO_ERROR,
  GOOGLE_CHARACTER_COUNT_ERROR,
  GOOGLE_DIGITAL_MEDIA_ERROR,
  GOOGLE_MULTIPLE_PHOTOS_ERROR,
  GOOGLE_PHONE_NUMBER_ERROR,
  GOOGLE_PHOTO_VIDEO_ERROR,
  GOOGLE_VIDEO_ERROR,
  INSTAGRAM_CONTENT_ERROR,
  NO_PLATFORMS_SELECTED_ERROR,
} from "../useSyncSocials/constants";

export const phone_regex = /(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/;

export function validateSocialContent(
  isGroupPost: boolean,
  facebook: boolean,
  google: boolean,
  instagram: boolean,
  message: string,
  attachedMedia: { data: string; type: string }[]
): string | null {
  if (!isGroupPost && !facebook && !google && !instagram)
    return NO_PLATFORMS_SELECTED_ERROR;

  if (google && message.length >= 1500) return GOOGLE_CHARACTER_COUNT_ERROR;

  if (instagram && attachedMedia?.length === 0) return INSTAGRAM_CONTENT_ERROR;

  const bothMediaTypes = checkIfPhotoAndVideo(attachedMedia);
  const atLeastTwoToggles =
    [facebook, google, instagram].filter(Boolean).length >= 2;

  if (bothMediaTypes && atLeastTwoToggles) return GENERAL_PHOTO_VIDEO_ERROR;
  if (bothMediaTypes && (facebook || isGroupPost)) {
    if (isGroupPost) {
      return FACEBOOK_GROUPS_PHOTO_VIDEO_ERROR;
    }
    return FACEBOOK_PHOTO_VIDEO_ERROR;
  }
  if (bothMediaTypes && google) return GOOGLE_PHOTO_VIDEO_ERROR;

  const isVideoAttached = attachedMedia.some(isVideo);
  if (isVideoAttached && google) return GOOGLE_VIDEO_ERROR;

  const multipleVideosAttached = checkIfMultipleVideos(attachedMedia);
  if (multipleVideosAttached && (facebook || isGroupPost))
    return FACEBOOK_PHOTO_VIDEO_ERROR;

  const multiplePhotosAttached = attachedMedia?.length > 1;
  if (multiplePhotosAttached && google) return GOOGLE_MULTIPLE_PHOTOS_ERROR;

  const isDigitalAssetAttached = checkIfDigitalAssetAttached(attachedMedia);
  if (isDigitalAssetAttached && google) return GOOGLE_DIGITAL_MEDIA_ERROR;

  const containsPhoneNumber = checkIfContainsPhoneNumber(message);
  if (containsPhoneNumber && google) return GOOGLE_PHONE_NUMBER_ERROR;

  return null;
}

function checkIfContainsPhoneNumber(message: string): boolean {
  return phone_regex.test(message);
}

function checkIfPhotoAndVideo(attachedMedia: { data: string }[]) {
  const photoAttached = attachedMedia.some((media) => !isVideoUrl(media.data));
  const videoAttached = attachedMedia.some((media) => isVideoUrl(media.data));
  return photoAttached && videoAttached;
}

function checkIfMultipleVideos(attachedMedia: { data: string }[]) {
  return attachedMedia.filter((media) => isVideoUrl(media?.data)).length > 1;
}

export function checkIfDigitalAssetAttached(
  atttachedMedia: { data: string; type: string }[]
): boolean {
  return (
    atttachedMedia.length > 0 &&
    atttachedMedia.some((photo) => photo?.type === "digital_asset")
  );
}
