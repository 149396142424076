import {
  BasicInfoProps,
  IReviewLink,
  ProfileInfo,
} from "../../../../contexts/types";
import { IContact } from "../../contacts/Contact/types";
import { sixMonthsPrior } from "../../contacts/ContactsTable/utils";
import { ChannelConfig, ChannelType } from "./types";

export function getDefaultRequestReviewForLandingPage(
  defaultMessage: any,
  firstName: any,
  lastName: any,
  companyName: any,
  phone: any,
  email: any
) {
  defaultMessage = defaultMessage?.replaceAll("*|FIRST_NAME|*", firstName);
  defaultMessage = defaultMessage?.replaceAll("*|LAST_NAME|*", lastName);
  defaultMessage = defaultMessage?.replaceAll("*|COMPANY_NAME|*", companyName);
  defaultMessage = defaultMessage?.replaceAll("*|PHONE|*", phone || "");
  defaultMessage = defaultMessage?.replaceAll("*|EMAIL|*", email || "");

  return defaultMessage;
}

export function checkIfAlreadySentRequestReviewWithin6Months(
  requestReviewDate: any
) {
  return requestReviewDate > sixMonthsPrior();
}

export const getReviewLink = (
  profileInfo: ProfileInfo,
  basicInfo: BasicInfoProps
) => {
  const { slug, domain } = profileInfo || {};
  const {
    review_link,
    review_link_more,
    leave_review_link_3,
    default_review_link,
    review_links,
  } = basicInfo || {};
  const custom_review_links =
    review_links?.map((link: IReviewLink) => link.link) || [];
  if (custom_review_links.includes(default_review_link))
    return default_review_link;

  const otherDefaultReviewLink = `${domain}/reviews`;

  switch (default_review_link) {
    case "Google":
      return review_link;
    case "Facebook":
      return leave_review_link_3;
    case "Other":
      return review_link_more || otherDefaultReviewLink;
    default:
      return otherDefaultReviewLink;
  }
};

export const determineSubAlert = (count: number) => {
  if (count && count > 0) {
    return "Unable to send multiple requests to a contact within a 6 month period";
  }
  return null;
};

export const determineInformativeMessage = (count: number) => {
  if (count === 1) {
    return "1 contact excluded from recipients";
  } else if (count > 1) {
    return `${count} contacts excluded from recipients`;
  }
  return "";
};

export const replaceMessageWithSingleName = (
  reviewMessage: string,
  recipientName: string
) => {
  return reviewMessage?.replace("*|HOMEOWNER_FIRST_NAME|*", recipientName);
};

export const adjustMessageForMultipleNames = (
  reviewMessage: string,
  firstName: string
) => {
  let message = reviewMessage;

  if (message.includes(`${firstName},`)) {
    message = message.replace(`${firstName},`, "*|HOMEOWNER_FIRST_NAME|*,");
  } else if (message.includes(`${firstName}!`)) {
    message = message.replace(`${firstName}!`, "*|HOMEOWNER_FIRST_NAME|*!");
  }

  return message;
};

// Parse recipients into contact IDs and non-contact recipients
export function parseRecipients(recipients: IContact[]) {
  const contactIds = recipients
    .filter((item) => item.id)
    .map((item) => item.id);
  const nonContactRecipients = recipients.filter((item) => item.value);

  return { contactIds, nonContactRecipients };
}

const isMatchingContact = (contact: IContact, recipient: IContact) => {
  return (
    contact.id === recipient.id ||
    (contact.phone_number && contact.phone_number === recipient.phone_number) ||
    (contact.email && contact.email === recipient.email)
  );
};

// Update the date when a review was sent for contacts
export function updateContactsForReviewSent(
  contacts: IContact[],
  recipients: IContact[]
): IContact[] {
  const today = new Date();

  return contacts.map((contact) => {
    const matchingRecipient = recipients.find((recipient) =>
      isMatchingContact(contact, recipient)
    );
    if (matchingRecipient) {
      return { ...contact, date_request_review_sent: today };
    }

    return contact;
  });
}

export function isContactMatchingSearch(
  contact: IContact,
  searchTerm: string
): boolean {
  const fullName = `${contact.first_name || ""} ${
    contact.last_name || ""
  }`.toLowerCase();
  const isNameMatch = fullName.includes(searchTerm);
  const isEmailMatch = contact.email?.toLowerCase().includes(searchTerm);
  const isPhoneMatch = contact.phone_number?.toLowerCase().includes(searchTerm);

  return isNameMatch || isEmailMatch || isPhoneMatch;
}

export function isContactNotInRecipients(
  contact: IContact,
  recipients: IContact[]
): boolean {
  return !recipients.some((recipient) => recipient.id === contact.id);
}
export const getCounterAndInvalidRecipients = (
  recipients: any[],
  initialCounter: number
) => {
  return recipients.reduce(
    (acc, contact) => {
      const bool = checkIfAlreadySentRequestReviewWithin6Months(
        new Date(contact?.date_request_review_sent)
      );

      if (bool) {
        acc.newCounter += 1;
        acc.invalidRecipientsVar.push(contact.id);
      }
      return acc;
    },
    { newCounter: initialCounter, invalidRecipientsVar: [] as any[] }
  );
};

export const filterAndSortContacts = (
  contacts: IContact[],
  invalidRecipients: number[],
  recipients: IContact[]
): IContact[] => {
  return contacts
    .filter(
      (contact: IContact) =>
        !invalidRecipients?.includes(contact.id) &&
        !recipients.some((r: IContact) => r.id === contact.id)
    )
    .sort((a, b) =>
      (a?.last_name || "")
        .toUpperCase()
        .localeCompare(b?.last_name?.toUpperCase())
    );
};

const hasPhoneNumber = (ele: IContact): boolean => !!ele.phone_number;
const hasEmail = (ele: IContact): boolean => !!ele.email;

export const channelTypeConfig: Record<ChannelType, ChannelConfig> = {
  text: {
    validator: hasPhoneNumber,
    alertMessage:
      "All recipients must have a phone number. Please click to edit or remove recipient.",
  },
  email: {
    validator: hasEmail,
    alertMessage:
      "All recipients must have an email. Please click to edit or remove recipient.",
  },
  send: {
    validator: () => true, // Always valid
    alertMessage: "", // No alert for this
  },
};
export function categorizeContacts(contacts: IContact[]) {
  let invalidContactsCount = 0;
  const invalidContactIDs: number[] = [];

  const validContacts = contacts.filter((contact) => {
    const recentlySent = checkIfAlreadySentRequestReviewWithin6Months(
      new Date(contact.date_request_review_sent)
    );

    if (recentlySent) {
      invalidContactsCount += 1;
      invalidContactIDs.push(contact.id);
    }

    return !recentlySent;
  });

  return {
    validContacts,
    invalidContactsCount,
    invalidContactIDs,
  };
}
