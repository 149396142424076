import ImageUpload from "../../../uiwrappers/ImageUpload/ImageUpload";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import BigGenerateMediaIcon from "../NewPost/BigGenerateMediaIcon";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import { getMaxPhotosText } from "./utils";
import AddPhotoIcon from "../../../common/Icons/AddPhotoIcon/AddPhotoIcon";

export default function DesktopPhotosButtons({
  processImages,
  startGenerateMedia,
  openPhotoSelectModal,
  toggleFacebookStatus,
  toggleGoogleStatus,
  toggleInstagramStatus,
  isGroupPost = false,
}: any) {
  return (
    <div className="flex flex-col items-end">
      <div style={{ width: 180 }} className="flex flex-shrink-0 flex-col">
        <div
          style={{ height: 138 }}
          className="flex h-full border border-blue-300 rounded-lg overflow-hidden bg-blue-50"
        >
          <div className="flex flex-col justify-center items-center w-full px-3.5">
            <div>
              <BigGenerateMediaIcon />
            </div>
            <div className="w-full pt-2">
              <SecondaryButton
                text="Generate Media"
                size="sm"
                onClickFunc={startGenerateMedia}
                customStyle="w-full"
              />
            </div>
          </div>
        </div>

        <div
          style={{ height: 138 }}
          className="flex h-full mt-4 border border-gray-300 rounded-lg overflow-hidden bg-gray-50"
        >
          <div className="flex flex-col justify-center items-center w-full px-3.5">
            <div>
              <AddPhotoIcon className="text-gray-500 w-8 h-8" strokeWidth={2} />
            </div>
            <div className="pt-2">
              <SecondaryButton
                text="Select from Gallery..."
                size="sm"
                onClickFunc={openPhotoSelectModal}
                customStyle="w-full"
              />
            </div>

            <div className="flex pt-2">
              <div className="text-sm-normal text-gray-600 mr-1">or</div>
              <ImageUpload processImages={processImages} multiple={true}>
                <PrimaryLink text="Upload" size="md" />
              </ImageUpload>
            </div>
          </div>
        </div>

        <div className="text-gray-500 text-sm-normal pt-4">
          {getMaxPhotosText(
            toggleFacebookStatus,
            toggleGoogleStatus,
            toggleInstagramStatus,
            isGroupPost
          )}
        </div>
      </div>
    </div>
  );
}
