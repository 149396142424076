import React from "react";
import PrimaryLink from "../../uiwrappers/PrimaryLink/PrimaryLink";
import GrayLink from "../../uiwrappers/GrayLink/GrayLink";

interface ModalActionButtonProps {
  text: string;
  type?: string;
  icon?: string;
}

export const ModalActionButton: React.FC<ModalActionButtonProps> = ({
  text,
  type,
  icon,
}: ModalActionButtonProps) => {
  switch (type) {
    case "primary":
    case "delete":
      return (
        <PrimaryLink
          text={text}
          size="md"
          icon={icon}
          textColor={type === "delete" ? "red" : "blue"}
        />
      );
    default:
      return <GrayLink text={text} size="md" icon={icon} />;
  }
};
